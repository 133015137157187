import React from 'react'
// import styled from '@emotion/styled'
import tw from 'tailwind.macro'
import BlockContent from '../block-content'
// import Container from '../container'

// import styles from '../project.module.css'

function Resources(props) {
  const ResourcesTitle = tw.h1`
  mt-2 px-0 pt-6 pb-4 text-xl md:text-2xl lg:text-3xl font-normal
  `
  const ResourcesBody = tw.div`
  pb-4 leading-normal
  `
  const SectionContainer = tw.section`
  text-black max-w-7xl w-full font-display px-4
  `
  return (
    <SectionContainer>
      <div>
        <ResourcesTitle>Resources</ResourcesTitle>
        {props.resources && (
          <ResourcesBody className="resources">
            <BlockContent blocks={props._rawResources || []} />
          </ResourcesBody>
        )}
      </div>
    </SectionContainer>
  )
}

export default Resources
